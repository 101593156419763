import React, { useState } from 'react';
import { Button, Text,Flex, Input, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast, useColorModeValue} from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { updateCandidatePersonalInfo } from '../../../../services/jobTemplateService';


export default function EditSocialProfiles(props) {
  const {candidate, job} = props;
  const profiles = JSON.parse(candidate?.social || "{}")
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');

  const onSubmitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const socialProfiles = {}
    for (const [key, value] of form.entries()) {
      socialProfiles[key] = value
    }
    let body = {
      email: getEmailId(),
      job_uuid: job?.job_uuid,
      candidate_uuid: candidate?.candidate_uuid,
      ptype: 'social',
      pdata: JSON.stringify(socialProfiles),
    };
    setIsFormSubmitting(true);
    updateCandidatePersonalInfo(body)
    .then( res => {
        if (res.result){
          props.getAllCandidatesForJob()
          toast({
            title: 'Candidate profiles updated succesfully.',
            status: 'success', 
            isClosable: true, 
            duration: 3000
          });
        } else {
          toast({
            title: 'Unable to update candidate profiles.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to update candidate profiles.', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })
    return false
  }

  return (
    <Drawer size={'lg'} isOpen={props.isEditSocialProfilesPanelOpen} onClose={props.onEditSocialProfilesPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader p={5}>Edit Social Profiles for {candidate?.first_name} {candidate?.last_name}</DrawerHeader>
        <DrawerCloseButton />
        <form onSubmit={onSubmitForm}>
          <Flex pl={'30px'} direction={'column'}>
              <Flex mt={5} alignItems={'center'}>
                <Text width={120}mr={5} fontSize={'16px'}>GitHub</Text>
                <Input defaultValue={profiles?.github} name={'github'} width={'350px'} />
              </Flex>

              <Flex mt={5} alignItems={'center'}>
                <Text width={120}mr={5} fontSize={'16px'}>Twitter</Text>
                <Input defaultValue={profiles?.twitter} name={'twitter'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
              <Text width={120} mr={5} fontSize={'16px'}>Facebook</Text>
              <Input  defaultValue={profiles?.facebook} name={'facebook'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
              <Text width={120}mr={5} fontSize={'16px'}>Instagram</Text>
              <Input defaultValue={profiles?.instagram} name={'instagram'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120} mr={5} fontSize={'16px'}>Kaggle</Text>
                <Input defaultValue={profiles?.kaggle} name={'kaggle'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Stackoverflow</Text>
                <Input defaultValue={profiles?.stackoverflow} name={'stackoverflow'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>AngelList</Text>
                <Input defaultValue={profiles?.angellist} name={'angellist'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Behance</Text>
                <Input defaultValue={profiles?.behance} name={'behance'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Crunchbase</Text>
                <Input defaultValue={profiles?.crunchbase} name={'crunchbase'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Pinterest</Text>
                <Input defaultValue={profiles?.pinterest} name={'pinterest'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Dribbble</Text>
                <Input defaultValue={profiles?.dribbble} name={'dribbble'} width={'350px'} />
              </Flex>

              <Flex alignItems={'center'} mt={5}>
                <Text width={120}mr={5} fontSize={'16px'}>Website</Text>
                <Input defaultValue={profiles?.website} name={'website'} width={'350px'} />
              </Flex>

              <Button type='submit' width={'200px'} mt={10} variant="brand" _hover={{'bg': brandColor}}
                  fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
              >
                Update
              </Button>
            </Flex>
        </form>  
      </DrawerContent>
    </Drawer>    
  );
}
