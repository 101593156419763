import { Link as ReactRouterLink } from 'react-router-dom';
import { Flex, Icon, useColorModeValue, Text, Link as ChakraLink, useToast, Tooltip, Spacer, IconButton, useDisclosure } from "@chakra-ui/react";
import { MdPhoneInTalk } from "react-icons/md";
import SocialIcon from "../../../common/SocialIcon";
import { BsLinkedin } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useState } from 'react';
import { BiCheck, BiPencil } from 'react-icons/bi';
import EditCandidateHeadlines from './EditCandidateHeadlines';
import EditSocialProfiles from './EditSocialProfiles';
import CandidateSalaryExpectations from '../../../common/CandidateSalaryExpectations';
import CandidateLocation from '../../../common/CandidateLocation';
import CandidateHighestEducation from '../../../common/CandidateHighestEducation';
import CandidateLatestJob from '../../../common/CandidateLatestJob';
import CandidateWorkStatus from '../../../common/CandidateWorkStatus';
import CandidateHeadshot from '../../../common/CandidateHeadshot';

export default function CandidateHeadlines(props){
    const {candidate, job, isEditCandidate, getAllCandidatesForJob, applyShadow=true, 
            headshotWidth='120px', headshotHeight='120px', headshotRadius='120px', mTop='30px',
            candidateNameFontSize='24px'
        } = props;
    const [isEditHeadlines, setIsEditHeadlines] = useState(false)
    const [isEditSocialProfiles, setIsEditSocialProfiles] = useState(false)
    const toast = useToast()
    const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
    const { isOpen: isEditHeadlinesPanelOpen, onOpen: onEditHeadlinesPanelOpen, onClose: onEditHeadlinesPanelClose } = useDisclosure();
    const { isOpen: isEditSocialProfilesPanelOpen, onOpen: onEditSocialProfilesPanelOpen, onClose: onEditSocialProfilesPanelClose } = useDisclosure();
    const bgShadow = {
        borderWidth: 0,
        borderColor: borderColorStyle,
        borderRadius: '2px',
        boxShadow: '0 0px 0px 0 lightgray,0 1px 5px 0 lightgray',
    }
    async function copyTextToClipboard(text) {
        return await navigator.clipboard.writeText(text);
    }

    const CandidateContacts = () => {
        return <>
          <Flex justifyContent={'center'} alignItems={'center'}>
              {candidate?.email
              ?<ChakraLink to={'javascript:void(0)'} onClick={ () => {
                  copyTextToClipboard(candidate?.email).then(
                      toast({
                          title: "Email copied to clipboard!",
                          status: "success",
                          duration: 3000
                      })
                  )
              }}>
                  <Tooltip label={`${candidate?.email}`} fontSize='md'>
                      <span><Icon as={AiOutlineMail} fontSize={'22px'} /></span>
                  </Tooltip>                        
              </ChakraLink>:null}
              {candidate?.phone && 
                  <ChakraLink to={'javascript:void(0)'} ml={1} onClick={ () => {
                      copyTextToClipboard(candidate?.phone).then(
                          toast({
                              title: "Phone copied to clipboard!",
                              status: "success",
                              duration: 3000
                          })
                      )
                  }}>
                      <Tooltip label={`${candidate?.phone}`} fontSize='md'>
                          <span><Icon as={MdPhoneInTalk} fontSize={'20px'} /></span>
                      </Tooltip>
                  </ChakraLink>
              }
              {candidate?.linkedin && 
              <ChakraLink as={ReactRouterLink} isExternal to={candidate?.linkedin} mr={2} ml={1}>
                  <Icon as={BsLinkedin} fontSize={'16px'} color={'blue.400'}/>
              </ChakraLink>
              }
            
          </Flex>
        </>
    }

    return <>
      <Flex mt={mTop} padding={'15px'} {...(applyShadow ? {...bgShadow} : {})} >
        <CandidateHeadshot candidate={candidate} width={headshotWidth} height={headshotHeight} borderRadius={headshotRadius} />
        <Flex direction={'column'} ml={'20px'} width={'100%'}>
            <Flex>
                <Text fontWeight={'600'} fontSize={candidateNameFontSize}>{candidate?.first_name} {candidate?.last_name}</Text>
                <Spacer />
                {isEditCandidate && !isEditHeadlines &&
                <Tooltip label={'Edit Profile Details'} fontSize='md'>
                    <span>
                        <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                            onClick={() => {
                                setIsEditHeadlines(true)
                                onEditHeadlinesPanelOpen()
                            }}
                        />
                    </span>
                </Tooltip>
                }
                {isEditCandidate && isEditHeadlines &&
                <Tooltip label={'Finish Editing Profile Details'} fontSize='md'>
                    <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                            onClick={() => {
                                setIsEditHeadlines(false)
                                onEditHeadlinesPanelClose()
                            }}
                        />
                    </span>
                </Tooltip>
                }
            </Flex>          
          

          <CandidateLocation candidate={candidate} mTop={2} />      
          <CandidateLatestJob candidate={candidate} rawData={candidate?.details?.raw_data} fontSize={'14px'} mTop={2} />
          <CandidateHighestEducation candidate={candidate} rawData={candidate?.details?.raw_data} mTop={2} />          
          <CandidateSalaryExpectations salary={JSON.parse(candidate?.expectation || "{}")?.salary} mTop={2}/>
          <CandidateWorkStatus candidate={candidate} mTop={2}/>

          <Flex alignItems={'center'} mt={5}>
            <CandidateContacts />
            {JSON.parse(candidate?.social || "{}") && <SocialIcon socialData={JSON.parse(candidate?.social || "{}")} />}
            {isEditCandidate && !isEditSocialProfiles &&
            <Tooltip label={'Edit Social Profiles'} fontSize='md'>
                <span>
                    <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                        onClick={() => {
                            setIsEditSocialProfiles(true)
                            onEditSocialProfilesPanelOpen()
                        }}
                    />
                </span>
            </Tooltip>
            }
            {isEditCandidate && isEditSocialProfiles &&
            <Tooltip label={'Finish Editing Social Profiles'} fontSize='md'>
                <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                        onClick={() => {
                            setIsEditSocialProfiles(false)
                            onEditSocialProfilesPanelClose()
                        }}
                    />
                </span>
            </Tooltip>
            }
          </Flex>
        </Flex>
      </Flex>

      <EditCandidateHeadlines
        candidate={candidate}
        job={job}
        onEditHeadlinesPanelOpen={onEditHeadlinesPanelOpen}
        onEditHeadlinesPanelClose={onEditHeadlinesPanelClose}
        isEditHeadlinesPanelOpen={isEditHeadlinesPanelOpen}
        getAllCandidatesForJob={getAllCandidatesForJob}
      />

      <EditSocialProfiles
        candidate={candidate}
        job={job}
        onEditSocialProfilesPanelOpen={onEditSocialProfilesPanelOpen}
        onEditSocialProfilesPanelClose={onEditSocialProfilesPanelClose}
        isEditSocialProfilesPanelOpen={isEditSocialProfilesPanelOpen}
        getAllCandidatesForJob={getAllCandidatesForJob}
      />
    </>
}